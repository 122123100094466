import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { RedeemLessonCreditQuery } from './query'
import { useStyles } from './styles'
import { gtmEvent } from 'lib/gtm'
import StudentCard from '../components/student-card'
import BackButton from 'components/back-button'
import CoachLessonHeader from '../components/CoachLessonHeader'
import StepDescriptionOfferings from '../step-description-offerings'
import themes, { extendTheme } from 'themes'
import { ThemeProvider } from '@material-ui/core'
import PropTypes from 'prop-types'
import ReviewBookingSkeleton from 'components/skeletons/review-booking-skeleton'
import ProgressIndicator from 'components/progress-indicators/progress-indicator'
import { ContainerRoot, WaiverContainer } from '../components/styles'
import { CoachingCenterWaiver } from 'components/waivers'
import { COACHING_CENTER_SLUGS } from 'utils/constants'
import { validationSchema } from '../utils'

const LessonReviewLayout = ({
  lessonType,
  locationId,
  startDateTime,
  student,
  previousUrl,
  setIsComplete,
  progressValue = 75,
  stepText = 'Step 3 of 3',
  eventObject = {},
}) => {
  const { coach, facilities } = lessonType
  const facility = facilities.find(({ id }) => id === locationId)
  const studentName = `${student.firstName} ${student.lastName}`
  const { enqueueSnackbar } = useSnackbar()
  const [redeemLesson, { loading }] = useMutation(RedeemLessonCreditQuery)
  const shouldShowWaiver = COACHING_CENTER_SLUGS.includes(
    lessonType?.academy?.slug,
  )
  const [showWaiver, setShowWaiver] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showWaiver])

  const form = useForm({
    resolver: yupResolver(validationSchema(false, shouldShowWaiver)),
    ...(shouldShowWaiver && {
      defaultValues: {
        agreeWaiver: false,
      },
    }),
  })

  const handleButtonClick = async () => {
    if (shouldShowWaiver) {
      setShowWaiver(true)
    } else {
      await form.handleSubmit(onSubmit)()
    }
  }

  const onSubmit = async () => {
    return redeemLesson({
      variables: {
        studentId: student.id,
        lessonTypeId: lessonType.id,
        startDateTime,
        locationId: facility.id,
      },
    })
      .then(({ data }) => {
        const { success, message } = data.redeemLessonCredit

        if (success) {
          enqueueSnackbar(message, {
            variant: 'success',
          })
          gtmEvent({
            ...eventObject,
            event: 'complete-private-session-booking',
          })
          // Remove the below event in the future.
          gtmEvent({
            event: 'formSubmit',
            formCategory: 'redeem-lesson',
            formAction: 'clicked-book-session-only-button',
          })

          setIsComplete(true)
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        window.rg4js &&
          window.rg4js('send', {
            error: error,
            tags: ['bookings', 'form-submit'],
          })
        enqueueSnackbar('An error has occurred. Please try again later.', {
          variant: 'error',
        })
      })
      .finally(() => {
        setShowWaiver(false)
      })
  }

  const classes = useStyles()

  if (loading) {
    return (
      <ContainerRoot className={classes.root}>
        <div className={classes.loadingStyles}>
          <ReviewBookingSkeleton />
        </div>
      </ContainerRoot>
    )
  }

  return (
    <>
      <WaiverContainer showWaiver={showWaiver}>
        <CoachingCenterWaiver
          form={form}
          showWaiver={showWaiver}
          setShowWaiver={setShowWaiver}
          onSubmit={onSubmit}
          withBackButton={true}
        />
      </WaiverContainer>
      <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
        <ContainerRoot
          className={classes.root}
          style={{ display: showWaiver ? 'none' : 'block' }}
        >
          <ProgressIndicator value={progressValue} />
          <Grid container>
            <Grid item xs={12}>
              <BackButton previousUrl={previousUrl} />
              <Grid item xs={12}>
                <CoachLessonHeader lessonType={lessonType} coach={coach} />
              </Grid>
              <Divider
                xs={12}
                style={{
                  margin: '16px 0 40px',
                }}
              />

              <StepDescriptionOfferings
                stepText={stepText}
                title="Review "
                description="Please review the information below."
              />
              <Grid item xs={12} sm={6} md={5}>
                <StudentCard student={student} studentName={studentName} />
              </Grid>
              <Divider style={{ margin: '2rem 0px 40px' }} />

              <Grid item md={5} xs={12}>
                <Box mb={4} textAlign="left">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClick}
                    className={classes.buttonStyles}
                    data-testid="complete-booking-button"
                    disabled={form.formState.isSubmitting}
                  >
                    Redeem Credit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </ContainerRoot>
      </ThemeProvider>
    </>
  )
}

LessonReviewLayout.propTypes = {
  lessonType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    durationInMinutes: PropTypes.number.isRequired,
    priceInCents: PropTypes.number,
    facilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        city: PropTypes.string,
        state: PropTypes.string,
      }),
    ).isRequired,
    description: PropTypes.string,
  }).isRequired,
  locationId: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  previousUrl: PropTypes.string.isRequired,
  setIsComplete: PropTypes.func.isRequired,
  progressValue: PropTypes.number,
  stepText: PropTypes.string,
}

export default LessonReviewLayout
