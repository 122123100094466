import React, { useState, useEffect, useRef } from 'react'
import { Container, Divider, Grid, LinearProgress } from '@material-ui/core'
import { centsToDollars } from 'lib/utils/number'
import { usePrepareRecordBookingPayment } from 'lib/graphql/mutations/prepare-record-booking-payment-mutation.js'
import CoachLessonHeader from 'pages/schedule/components/CoachLessonHeader'
import StepDescriptionOfferings from 'pages/schedule/components/step-description-offerings'
import StudentCard from 'pages/payment-request/components/student-card'
import PaymentSummary from 'pages/payment-request/components/payment-summary'
import PaymentForm from './components/payment-form'
import { useStyles } from './styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'env'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

const RequestPaymentForm = ({ enrollment }) => {
  const [clientSecret, setClientSecret] = useState('')
  const { lesson, student } = enrollment
  const { lessonType } = lesson
  const { priceInCents } = lessonType
  const stripePromise = loadStripe(STRIPE_KEY)
  const paymentFormRef = useRef()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [
    prepareRecordBookingPayment,
    { data, loading },
  ] = usePrepareRecordBookingPayment()

  useEffect(() => {
    if (enrollment) {
      prepareRecordBookingPayment({
        variables: {
          enrollmentId: enrollment.id,
        },
      })
    }
  }, [prepareRecordBookingPayment, enrollment])

  useEffect(() => {
    if (data?.prepareRecordBookingPayment?.success) {
      setClientSecret(
        data?.prepareRecordBookingPayment?.paymentIntent?.clientSecret,
      )
    }
  }, [data])

  const appearance = {
    theme: isSubmitting ? 'flat' : 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
    readOnly: isSubmitting,
  }

  const classes = useStyles()

  const priceInDollars = centsToDollars(priceInCents)
  const studentName = `${student.firstName} ${student.lastName}`
  const returnUrl = `${enrollment.paymentRequestToken}/success`
  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container style={{ flexDirection: 'column' }}>
        <CoachLessonHeader
          lessonType={{ startDateTime: lesson.startDateTime, ...lessonType }}
          coach={lesson.coach}
        />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12} md={7} className={classes.stepContainer}>
            <StepDescriptionOfferings
              title="Review"
              description="Please review the information below."
            />
            <StudentCard studentName={studentName} />
            <Divider className={classes.divider} />
            <PaymentSummary priceInDollars={priceInDollars} />
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <PaymentForm
                  ref={paymentFormRef}
                  enrollment={enrollment}
                  redirectUrl={returnUrl}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  clientSecret={clientSecret}
                />
                <Button
                  variant="outlined"
                  onClick={() => paymentFormRef.current.submitForm()}
                  className={classes.bookingButton}
                  data-testid="complete-booking-button"
                  disabled={isSubmitting}
                >
                  Complete Purchase
                </Button>
              </Elements>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
export default RequestPaymentForm

RequestPaymentForm.propTypes = {
  enrollment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    paymentRequestToken: PropTypes.string.isRequired,
    lesson: PropTypes.shape({
      startDateTime: PropTypes.string.isRequired,
      coach: PropTypes.shape({
        coachProfile: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
      lessonType: PropTypes.shape({
        title: PropTypes.string.isRequired,
        priceInCents: PropTypes.number.isRequired,
      }),
      student: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  }),
}
