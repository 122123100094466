import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import pluralize from 'lib/pluralize'
import { isRecurringPayment } from 'utils/groupEventUtils'
import { PGA_COM_HOST } from 'env'
import { composeOptimizedImageUrl } from 'lib/utils'

const useStyles = makeStyles(theme => ({
  featuredImage: {
    backgroundImage: ({ featuredImageUrl }) =>
      `url("${composeOptimizedImageUrl(featuredImageUrl, 400)}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '8rem',
    width: '8rem',
    borderRadius: '4px',
    flexShrink: 0,
  },
  eventTitle: {
    lineHeight: '22px',
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
  eventDetail: {
    lineHeight: '22px',
    color: theme.palette.text.secondary,
  },
  messageLink: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Roboto',
  },
  coachingCenterLink: {
    color: theme.palette.text.secondary,
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.15px',
    '& a': {
      color: theme.palette.text.secondary,
      '&:visited': {
        color: theme.palette.text.secondary,
      },
    },
  },
}))

const EventInfo = ({ groupEvent, hideMessageLink = false }) => {
  const {
    maxAttendance,
    registrationCount,
    featuredImageUrl,
    isCanceled,
    registrationClosed,
    registrationFull,
    slug,
    timezone,
  } = groupEvent

  const isRegistrationEnabled = !(
    isCanceled ||
    registrationClosed ||
    registrationFull
  )

  const spotsRemaining =
    maxAttendance != null && maxAttendance - registrationCount

  const shouldDisplaySpotsRemaining =
    isRegistrationEnabled &&
    spotsRemaining &&
    spotsRemaining > 0 &&
    spotsRemaining <= 10

  const classes = useStyles({ featuredImageUrl })
  const isFriscoCoachingCenterGroupEvent =
    groupEvent?.academy?.slug === 'pga-coaching-center-at-pga-frisco'
  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.featuredImage} />
      <Box pl={3} display="flex" flexDirection="column">
        <Typography variant="body1" className={classes.eventTitle}>
          {groupEvent.title}
        </Typography>
        {isFriscoCoachingCenterGroupEvent ? (
          <Typography variant="body1" className={classes.coachingCenterLink}>
            <a
              href={`${PGA_COM_HOST}/pga-coaching-center-at-pga-frisco`}
              rel="noopener noreferrer"
            >
              {groupEvent.coachFacility.name}
            </a>
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.eventDetail}>
            {groupEvent.coachFacility.name}
          </Typography>
        )}
        <Typography variant="body1" className={classes.eventDetail}>
          {isRecurringPayment(groupEvent) && 'Starts '}
          {DateTime.fromISO(groupEvent.startDateTime)
            .setZone(timezone)
            .toFormat('ccc, DD')}
        </Typography>
        {groupEvent.lessonCount > 1 && (
          <Typography variant="body1" className={classes.eventDetail}>
            {groupEvent.lessonCount} sessions
          </Typography>
        )}
        <Typography variant="body1" className={classes.eventDetail}>
          {DateTime.fromISO(groupEvent.startDateTime)
            .setZone(timezone)
            .toFormat('h:mm')}{' '}
          –{' '}
          {DateTime.fromISO(groupEvent.endDateTime)
            .setZone(timezone)
            .toFormat('h:mm a (ZZZZ)')}
        </Typography>
        {!hideMessageLink && (
          <Link
            variant="body1"
            TypographyClasses={{ root: classes.messageLink }}
            component={RouterLink}
            to={`/things-to-do/events/${slug}/messages`}
          >
            Message Event Host
          </Link>
        )}
        <Box display={shouldDisplaySpotsRemaining ? 'block' : 'none'}>
          <Typography
            variant="caption"
            color="error"
            style={{ fontWeight: 700 }}
          >
            {spotsRemaining} {pluralize(spotsRemaining, 'spot')} remaining
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default EventInfo
