import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PageHeader } from 'components/page-header'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from 'lib/auth'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import { MY_PGA_COM_HOST, STRIPE_CUSTOMER_PORTAL_URL } from 'env'
import { useSnackbar, useSnackbarError } from 'lib/snackbar'
import { useCreateSubscriptionPaymentSession } from 'lib/graphql/mutations/create-subscription-payment-session'
import pgaCoachPlusLogo from 'images/pga-coach-plus-logo.svg'
import pgaCoachLogo from 'images/pga-coach-logo.png'

const useStyles = makeStyles(theme => ({
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    '& li:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
    },
  },
}))

const MyPlan = () => {
  const styles = useStyles()
  const { path } = useRouteMatch()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const snackbarError = useSnackbarError(enqueueSnackbar)
  const successUrl = `${MY_PGA_COM_HOST}/pga-coach/checkout/success`
  const [
    callCreateSubscriptionPaymentSession,
    { loading },
  ] = useCreateSubscriptionPaymentSession()

  const hasPaidTierSubscription = user?.coach?.hasPaidTierSubscription
  const crumbs = [
    {
      label: 'Account',
      to: path
        .split('/')
        .slice(0, 2)
        .join('/'),
    },
  ]
  const PgaCoachOptions = [
    'Profile on PGA.com',
    'Limited offering types',
    'Limited leads',
    'Online payments',
    'Student management',
    'Notes',
  ]
  const PgaCoachPlusOptions = [
    'Unlimited leads',
    'Unlimited offering types',
    'Group event waitlisting',
    'Promotional opportunities',
    'Personalized customer success',
    'Dedicated concierge support',
    'Access to special partner opportunities',
    'First access to new features',
    'Access to exclusive content',
  ]

  const handleUpgradeClick = async () => {
    try {
      const { data } = await callCreateSubscriptionPaymentSession({
        variables: { successUrl },
      })
      const {
        url,
        success,
        message,
      } = data?.createPgaCoachSubscriptionPaymentSession
      if (!success) {
        return snackbarError(message)
      }
      if (success && url) {
        window.location.href = url
      }
    } catch (e) {
      snackbarError(e?.message)
    }
  }

  const headerAction = hasPaidTierSubscription ? (
    <Button
      startIcon={<OpenInNewOutlinedIcon />}
      variant="contained"
      color="primary"
      target="_blank"
      href={STRIPE_CUSTOMER_PORTAL_URL}
    >
      Manage Plan
    </Button>
  ) : (
    <Button
      startIcon={<OpenInNewOutlinedIcon />}
      variant="contained"
      color="primary"
      onClick={handleUpgradeClick}
    >
      Upgrade
    </Button>
  )

  return (
    <>
      <PageHeader title="My Plan" crumbs={crumbs} actions={headerAction} />
      {loading && <LinearProgress />}
      <Box my={[3, 6]}>
        <Container maxWidth="lg">
          <Typography variant="h6" gutterBottom>
            My Plan
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Discover the perfect coaching solution with PGA Coach: start for
            with PGA Coach or upgrade to PGA Coach+ for a complete business
            suite and manage everything from booking to marketing with ease.
          </Typography>
          <Card variant="outlined" style={{ marginTop: '24px' }}>
            <CardHeader
              title={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                  px={[1, 4]}
                >
                  <img
                    src={pgaCoachLogo}
                    alt="PGA Coach"
                    style={{ height: '36px' }}
                  />
                  {!hasPaidTierSubscription && (
                    <Chip label="Your Plan" color="secondary" />
                  )}
                </Box>
              }
              style={{ borderBottom: '1px solid #E0E0E0', maxHeight: '40px' }}
            />
            <Box className={styles.cardContent}>
              <ul>
                {PgaCoachOptions.map((option, index) => (
                  <li key={index}>
                    <Typography variant="body2">{option}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Card>
          <Card variant="outlined" style={{ marginTop: '28px' }}>
            <CardHeader
              title={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1}
                  px={[1, 4]}
                >
                  <img src={pgaCoachPlusLogo} alt="PGA Coach Plus" />
                  {hasPaidTierSubscription && (
                    <Chip label="Your Plan" color="secondary" />
                  )}
                </Box>
              }
              style={{ borderBottom: '1px solid #E0E0E0' }}
            />
            <Box className={styles.cardContent}>
              <Typography
                variant="body2"
                style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
              >
                Includes all PGA Coach features, plus:
              </Typography>
              <ul>
                {PgaCoachPlusOptions.map((option, index) => (
                  <li key={index}>
                    <Typography variant="body2">{option}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default MyPlan
