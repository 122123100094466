import { gql } from '@apollo/client'

export const LessonReviewQuery = gql`
  query LessonReviewQuery($id: ID!) {
    lessonTypes(id: $id) {
      id
      category
      title
      durationInMinutes
      priceInCents
      facilities {
        id
        name
        timezone
        city
        state
      }
      coach {
        name
        bookingUrl
        coachProfile {
          title
          profilePhoto
          memberType
          certificationLevel
        }
      }
      acceptsOnlinePayments
      lessonPacks {
        quantity
        priceInCents
        id
      }
    }
  }
`

export const CREATE_LESSON_FOR_STUDENT_QUERY = gql`
  mutation CreateLessonForStudent($input: CreateLessonForStudentInput!) {
    createLessonForStudent(input: $input) {
      code
      success
      message
      lesson {
        id
      }
    }
  }
`

export const REDEEM_LESSON_CREDIT_QUERY = gql`
  mutation RedeemLessonCredit(
    $lessonTypeId: ID!
    $locationId: ID!
    $startDateTime: ISO8601DateTime!
    $studentId: ID!
  ) {
    redeemLessonCredit(
      lessonTypeId: $lessonTypeId
      locationId: $locationId
      startDateTime: $startDateTime
      studentId: $studentId
    ) {
      success
      message
    }
  }
`

export const PurchaseLessonPackQuery = gql`
  mutation PurchaseLessonPack($lessonPackId: ID!, $stripeToken: String!) {
    purchaseLessonPack(lessonPackId: $lessonPackId, stripeToken: $stripeToken) {
      success
      message
    }
  }
`

export const GetStudentQuery = gql`
  query GetStudentQuery($id: ID!) {
    currentUser {
      students(id: $id) {
        id
        firstName
        lastName
      }
    }
  }
`
