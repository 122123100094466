import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import GroupEventInfo from './group-event-info'
import ContactInformation from './contact-information'
import ParticipantInformation from './participant-information'
import PriceDetails from './price-details'
import SignInNudge from './sign-in-nudge'
import PaymentInformation from './payment-information'
import RegisterButton from './register-button'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import useStyles from './group-event-registration-form.styles'
import { COACHING_CENTER_SLUGS } from 'utils/constants'
import { CANCELLATION_POLICY } from '../constants'
import { useTheme } from '@material-ui/core/styles'
import MarketingOptIn from 'components/marketing-opt-in/marketing-opt-in'
import Link from '@material-ui/core/Link'
import { useAuth } from 'lib/auth'
import CoachInfo from './coach-info'

const DesktopLayout = ({
  groupEvent,
  paymentRequired,
  setPaymentRequired,
  shouldShowWaiver,
  handleRegisterClick,
  marketingOptIn,
  marketingOptInChecked,
  handleMarketingOptInChange,
}) => {
  const isCoachingCenterGE = COACHING_CENTER_SLUGS.includes(
    groupEvent?.academy?.slug,
  )
  // we want to show the full description with cancellation policy for coaching center events
  const MAX_LINES = isCoachingCenterGE ? 1000 : 50

  const description = isCoachingCenterGE
    ? groupEvent?.description + CANCELLATION_POLICY
    : groupEvent?.description

  const { user, isConsumer } = useAuth()
  const userIsNotOptedIn = !user?.marketingOptInAt
  const isAuthenticatedConsumer = user && isConsumer
  const [isDescriptionClamped, setIsDescriptionClamped] = useState(false)
  const [showFullDescription, setShowFullDescription] = useState(
    isCoachingCenterGE,
  )
  const [showButton, setShowButton] = useState(false)
  const [maxLines, setMaxLines] = useState(isCoachingCenterGE ? MAX_LINES : 2)
  const descriptionRef = useRef(null)
  const classes = useStyles({ maxLines })
  const theme = useTheme()

  useEffect(() => {
    const contentElement = descriptionRef.current
    if (contentElement) {
      const isContentOverflowing =
        contentElement.scrollHeight > contentElement.clientHeight ||
        contentElement.scrollWidth > contentElement.clientWidth
      setIsDescriptionClamped(isContentOverflowing && !isCoachingCenterGE)
      setShowButton(isContentOverflowing || isCoachingCenterGE)
    }
  }, [description, isCoachingCenterGE])

  const handleFullDescription = e => {
    e.preventDefault()

    if (isDescriptionClamped) {
      setMaxLines(MAX_LINES)
      setShowFullDescription(true)
      setIsDescriptionClamped(false)
    } else {
      setMaxLines(2)
      setShowFullDescription(false)
      setIsDescriptionClamped(true)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py={6}
      mx={2}
      position="relative"
    >
      <Box
        mr={2}
        p={4}
        borderRadius={16}
        style={{
          backgroundColor: 'white',
          width: '525px',
          height: 'fit-content',
        }}
      >
        <SignInNudge />
        <ParticipantInformation tags={groupEvent?.tags} />
        <Box mb={2}>
          <ContactInformation />
        </Box>
        <Box mb={4}>
          <PaymentInformation
            groupEvent={groupEvent}
            paymentRequired={paymentRequired}
            setPaymentRequired={setPaymentRequired}
          />
          {marketingOptIn && userIsNotOptedIn && (
            <MarketingOptIn
              checked={marketingOptInChecked}
              onChange={handleMarketingOptInChange}
              useMarketingOptIn={'callout'}
            />
          )}
        </Box>
        {!isAuthenticatedConsumer && (
          <Typography variant="body2" className={classes.proceedingText}>
            By proceeding, you agree to PGA of America’s{' '}
            <Link
              className={classes.link}
              href="https://www.pga.com/pga-of-america/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              className={classes.link}
              href="https://www.pga.com/pga-of-america/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </Link>
            .
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <RegisterButton
            groupEvent={groupEvent}
            paymentRequired={paymentRequired}
            onClick={handleRegisterClick}
            type={shouldShowWaiver ? 'button' : 'submit'}
          />
        </Box>
      </Box>
      <Box
        p={4}
        borderRadius={16}
        style={{
          backgroundColor: 'white',
          width: '480px',
          height: 'fit-content',
          top: '48px',
        }}
        position="sticky"
      >
        <GroupEventInfo groupEvent={groupEvent} />
        <Divider className={classes.horizontalDivider} />
        {isCoachingCenterGE && (
          <>
            <PriceDetails
              groupEvent={groupEvent}
              paymentRequired={paymentRequired}
            />
            <Divider className={classes.horizontalDivider} />
          </>
        )}
        <CoachInfo coach={groupEvent?.coach} />
        <Divider className={classes.horizontalDivider} />
        {description && (
          <div>
            <div>
              <Typography
                variant="subtitle1"
                component="h1"
                className={classes.subTitle}
              >
                Description
              </Typography>
              <div ref={descriptionRef} className={classes.clampedDescription}>
                <ReactMarkdown>{description}</ReactMarkdown>
              </div>
            </div>
            {showButton ? (
              <Button
                onClick={handleFullDescription}
                style={{ paddingLeft: 0 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: theme.palette.primary.light }}
                >
                  {showFullDescription ? 'Read Less' : 'Read More'}
                </Typography>
              </Button>
            ) : null}
          </div>
        )}
        {!isCoachingCenterGE && (
          <>
            <Divider className={classes.horizontalDivider} />
            <PriceDetails
              groupEvent={groupEvent}
              paymentRequired={paymentRequired}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default DesktopLayout
