import * as flagrApi from './flagrApi'
import { flags } from './constants'
import { ExperimentFlags, InternalFlags } from './store'
import { datadogRum } from '@datadog/browser-rum'

const isExperimentFlag = name => name.startsWith('my-pga-com-exp')

/* eslint-disable no-unused-vars */
const valueFlag = name => ({ name, init: value => value })
const booleanFlag = name => ({ name, init: value => value === 'true' })

const FLAGS = [
  booleanFlag(flags.FLAG_EXPEDITED_SHIPPING_DISABLED),
  booleanFlag(flags.FLAG_FEAT_GROUP_EVENT_RECURRING_PAYMENTS_ENABLED),
  booleanFlag(flags.FLAG_STUDENT_REVENUE_STAT),
  booleanFlag(flags.FLAG_REFUND_QUICK_ACTION),
  booleanFlag(flags.FLAG_APP_INSTALL_BANNER_AD),
  booleanFlag(flags.FLAG_FEAT_COACHING_CENTER_STUDENT_LIST_MENU_MANAGEMENT),
  booleanFlag(flags.FLAG_FEAT_HOME_AWAY_EVENTS),
  booleanFlag(flags.FLAG_FEAT_ENFORCE_FCC),
  booleanFlag(flags.FLAG_FEAT_BYPASS_JRL_REG),
  booleanFlag(flags.FLAG_FEAT_STREAM_THREADS),
  booleanFlag(flags.FLAG_FEAT_FUNDAMENTAL_MOVEMENT_SKILLS),
  booleanFlag(flags.FLAG_FEAT_STUDENT_CANCELLATION),
  booleanFlag(flags.FLAG_FEAT_AGE_SPECIFIC_GROUP_EVENTS),
  valueFlag(flags.FLAG_FEAT_MARKETING_OPT_IN_EXP),
  booleanFlag(flags.FLAG_FEAT_MARKETING_OPT_IN_LESSON_PACKS),
  booleanFlag(flags.FLAG_FEAT_MARKETING_OPT_IN_GROUP_EVENTS),
  booleanFlag(flags.FLAG_FEAT_MARKETING_OPT_IN_PRIVATE_BOOKINGS),
  booleanFlag(flags.FLAG_FEAT_GROUP_EVENT_RSVP),
  booleanFlag(flags.FLAG_FEAT_HOPE_GRADUATE),
  booleanFlag(flags.FLAG_FEAT_COACH_BUSINESS_INSIGHTS),
  booleanFlag(flags.FLAG_FEAT_LESSON_PACK_GIFTING),
  booleanFlag(flags.FLAG_FEAT_JRL_PROCESSING_FEE),
  booleanFlag(flags.FLAG_FEAT_NEW_JRL_DONATIONS_PAGE),
  booleanFlag(flags.FLAG_FEAT_CC_MEMBER_BAY_RESERVATIONS),
  booleanFlag(flags.FLAG_FEAT_CONTACT_WEBCAL),
  booleanFlag(flags.FLAG_PAID_TIER_ACTIVE),
  booleanFlag(flags.FLAG_SUBSCRIPTION_REGISTRATION_ENABLED),
  booleanFlag(flags.FLAG_FEAT_KIT_ORDER_APPROVAL),
  booleanFlag(flags.FLAG_SHOW_ACCEPT_LEADS_SETTING),
]

const initializeFeatures = async user => {
  // retrieve experiment flag values from previous visits
  // also apply any internal overrides from /ab
  let flagValues = {
    ...ExperimentFlags.get(),
    ...InternalFlags.get(),
  }

  // get the list of active flags for this application
  let activeFlagKeys = new Set()
  try {
    activeFlagKeys = await flagrApi.fetchActiveFlagKeys()

    // perform evaluations for any flags the user has not been evaluated for
    const userFlagKeys = new Set(Object.keys(flagValues))
    const flagKeysToEvaluate = Array.from(activeFlagKeys).filter(
      k => !userFlagKeys.has(k),
    )
    if (flagKeysToEvaluate.length > 0) {
      const flagEvaluations = await flagrApi.evaluateFlags(
        flagKeysToEvaluate,
        user,
      )
      flagValues = {
        ...flagValues,
        ...flagEvaluations,
      }
    }
  } catch (e) {
    window.rg4js &&
      window.rg4js('send', {
        error: e,
        tags: ['flagr'],
      })
  }

  // prune any inactive flags
  flagValues = Object.keys(flagValues)
    .filter(f => activeFlagKeys.has(f))
    .reduce((acc, val) => {
      acc[val] = flagValues[val]
      return acc
    }, {})

  // set the experiment flag cookie for future visits
  if (user != null) {
    const experimentFlagValues = Object.keys(flagValues)
      .filter(f => isExperimentFlag(f))
      .reduce((acc, val) => {
        acc[val] = flagValues[val]
        return acc
      }, {})
    ExperimentFlags.set(experimentFlagValues)
  }

  const evaluatedFlags = FLAGS.reduce(
    (acc, flag) => ({
      ...acc,
      [flag.name]: flag.init(flagValues[flag.name]),
    }),
    {},
  )

  for (const [key, value] of Object.entries(evaluatedFlags)) {
    datadogRum.addFeatureFlagEvaluation(key, value)
  }

  return evaluatedFlags
}

export default initializeFeatures
