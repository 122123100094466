import React from 'react'
import { getInitials } from 'lib/text-display'
import Avatar from '@material-ui/core/Avatar'
import CoachLogo from 'components/coach-logo'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from './group-event-registration-form.styles'

const CoachInfo = ({ coach }) => {
  const classes = useStyles()
  const { name, coachProfile } = coach
  const { title, profilePhoto, memberType, certificationLevel } =
    coachProfile || {}
  const initials = getInitials(coach.name)
  return (
    <Box display="flex" alignItems="center">
      <Avatar className={classes.coachAvatar} alt={name} src={profilePhoto}>
        {initials}
      </Avatar>
      <Box>
        <Typography variant="h5">{name}</Typography>
        {title ? (
          <Typography variant="caption" className={classes.coachTitle}>
            {title}
          </Typography>
        ) : null}
      </Box>
      <Box marginLeft="auto">
        <CoachLogo {...{ memberType, certificationLevel, size: '60px' }} />
      </Box>
    </Box>
  )
}

export default CoachInfo
