import { JRL_MARKETING_SITE_URL, MY_PGA_COM_HOST } from 'env'

export const urls = {
  home: '/',
  search: '/junior-league-events/search',
  localStandings: '/play/local-league-standings',
  how: '/learn/how-it-works',
  faq: '/learn/faq',
  news: '/learn/news',
  history: '/learn/history',
  coachBecome: '/coach/become-a-coach',
  coachResources: '/coach/coaching-resources',
  privacy: 'https://www.pga.com/pga-of-america/privacy-policy',
  terms: '/terms',
  coachLogin: `${MY_PGA_COM_HOST}/junior-league`,
  playerLogin: MY_PGA_COM_HOST,
  twitter: 'https://twitter.com/PGAjrLeague?lang=en',
  facebook: 'https://www.facebook.com/PGAjrLeague/',
  instagram: 'https://www.instagram.com/PGAjrLeague',
  // youtube: 'https://youtube.com/',
  pgaCom: 'https://www.pga.com',
  doNotSell:
    'https://privacyportal.onetrust.com/webform/d026f30a-622a-40b3-b261-d78a6b839240/e468c3de-717f-44a2-8576-29799026c21a',
  partnersPrograms: '/learn/partners-and-programs',
  championshipSeason: `${JRL_MARKETING_SITE_URL}/compete/championship-season`,
  allStar: '/junior-league-events/regions',
  qualifiers: '/junior-league-events/section-qualifiers-championships',
  regionals: '/junior-league-events/regionals',
  national: '/junior-league-events/pga-jr-league-championship/',
  donate: '/donate',
  requirements:
    'https://docs.google.com/document/d/e/2PACX-1vSbsVQJUpG6Y_zDjSW0QWMrIZ8H4JGN26pKm2gw7W5TuFGCI1V9N34e6_i5_ra0MGA0JENCH3wyOs-j/pub',
  macronStore:
    'https://clubshop.macron.com/macron_sportswear_us/pga-merchandise-store/merchandising',
}

export const topLeftMenu = [
  {
    text: 'Home',
    url: urls.home,
  },
  {
    text: 'Learn',
    subMenu: [
      {
        text: 'How It Works',
        url: urls.how,
      },
      {
        text: 'Partners & Programs',
        url: urls.partnersPrograms,
      },
      {
        text: 'News',
        url: urls.news,
      },
    ],
  },
  {
    text: 'Play',
    subMenu: [
      {
        text: 'Find a Program',
        url: urls.search,
      },
      {
        text: 'Local League Standings',
        url: urls.localStandings,
      },
      {
        text: '#GameChanger Club',
        url: '#',
      },
    ],
  },
  {
    text: 'Compete',
    subMenu: [
      {
        text: 'All-Star Season',
        url: urls.championshipSeason,
      },
      {
        text: 'All-Star Play Days & Leaderboard',
        url: urls.allStar,
        style: { marginLeft: '20px' },
      },
      {
        text: 'Sectionals',
        url: urls.qualifiers,
        style: { marginLeft: '20px' },
      },
      {
        text: 'Regionals',
        url: urls.regionals,
        style: { marginLeft: '20px' },
      },
      // {
      //   text: 'PGA Jr. League Championship',
      //   url: urls.national,
      // },
      {
        text: 'PGA Jr. League Championships',
        url: urls.national,
      },
    ],
  },
  {
    text: 'Coach',
    subMenu: [
      {
        text: 'Become a Coach',
        url: urls.coachBecome,
      },
      {
        text: 'Coach Login',
        url: urls.coachLogin,
      },
    ],
  },
  {
    text: 'Donate',
    url: urls.donate,
  },
  {
    text: 'Shop',
    url: urls.macronStore,
    openInNewTab: true,
  },
]

export const footerMenus = {
  leftTop: [
    {
      text: 'Home',
      url: urls.home,
    },
    {
      text: 'How it Works',
      url: urls.how,
    },
    {
      text: 'Partners & Programs',
      url: urls.partnersPrograms,
    },
    {
      text: 'News',
      url: urls.news,
    },
  ],
  leftBottom: [
    {
      text: 'Find Program',
      url: urls.search,
    },
    {
      text: 'Local League Standings',
      url: urls.localStandings,
    },
    {
      text: '#GameChanger Club',
      url: '#',
    },
  ],
  rightTop: [
    {
      text: 'All-Star Season',
      url: urls.championshipSeason,
    },
    {
      text: 'All-Star Play Days & Leaderboard',
      url: urls.allStar,
    },
    {
      text: 'Sectionals',
      url: urls.qualifiers,
    },
    {
      text: 'Regionals',
      url: urls.regionals,
    },
    {
      text: 'PGA Jr. League Championship',
      url: urls.national,
    },
  ],
  rightBottom: [
    {
      text: 'Become a Coach',
      url: urls.coachBecome,
    },
    {
      text: 'Coach Login',
      url: urls.coachLogin,
    },
  ],
  bottom: [
    {
      text: 'Terms of Service',
      url: urls.terms,
    },
    {
      text: 'Privacy Policy',
      url: urls.privacy,
    },
    {
      text: 'California Privacy Notice',
      url: 'https://www.pga.com/pga-of-america/california-privacy-notice',
    },
    {
      text: 'Do Not Sell or Share My Personal Information',
      url: urls.doNotSell,
    },
  ],
}
