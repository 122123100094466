import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Box from '@material-ui/core/Box'
import { ThemeProvider } from '@material-ui/core/styles'
import themes, { extendTheme } from 'themes'
import { withProtectedRoute, roles, useAuth } from 'lib/auth'
import Shell from 'components/shell/shell'
import DashboardReturnLink from 'components/shell/dashboard-return-link'
import AccountDropdown from 'components/account-dropdown'
import PaymentsPage from 'pages/account/payments'
import PaymentAccountsPage from 'pages/account/payments/payment-accounts'
import NewPaymentAccountCallbackPage from 'pages/account/payments/payment-accounts/new'
import TransactionsPage from 'pages/account/payments/transactions'
import TransactionDetailPage from 'pages/account/payments/transactions/transaction-detail'
import AccountDetails from 'pages/account/account-details'
import AccountAcademyCoachesPage from 'pages/account/academy-coaches'
import RequirementsPage from 'components/requirements'
import SensitiveInfoWrapper from 'lib/auth/SensitiveInfoWrapper'
import { flags, useFeatureFlags } from 'lib/feature-flags'
import CoachingCenterStudentsList from 'pages/academy/coaching-center-students/students-list/index'
import CoachingCenterPageHeader from 'components/page-header/coaching-center-page-header'
import StudentsPage from 'pages/account/students'
import GolferIntakeForm from 'pages/club/golfer-intake-form'
import GolferAccountDetails from '../pages/club/golfer-account-details'
import EditGolferAccountDetails from '../pages/club/golfer-account-details/edit-account-details'
import ReservationsShowPage from '../pages/account/reservations'
import MyPlan from '../pages/account/my-plan'

// icons
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined'
import { GolferIcon } from 'components/icons/refreshed-icons'

const AccountRoutes = () => {
  const { path } = useRouteMatch()
  const { user, isCoach } = useAuth()
  const [
    isCoachingCenterStudentListVisible,
    isSubscriptionRegistrationEnabled,
  ] = useFeatureFlags([
    flags.FLAG_FEAT_COACHING_CENTER_STUDENT_LIST_MENU_MANAGEMENT,
    flags.FLAG_SUBSCRIPTION_REGISTRATION_ENABLED,
  ])

  const menuItems = (() => {
    if (isCoach) {
      const coachMenuItems = [
        {
          icon: <AccountCircleOutlinedIcon />,
          label: 'Account Details',
          path,
          exact: true,
        },
      ]

      if (isSubscriptionRegistrationEnabled) {
        coachMenuItems.push({
          icon: <CardMembershipOutlinedIcon />,
          label: 'My Plan',
          path: `${path}/my-plan`,
          exact: true,
        })
      }

      if (!user.hideSensitiveInformation) {
        coachMenuItems.push({
          icon: <AttachMoneyOutlinedIcon />,
          label: 'Payments',
          path: `${path}/payments`,
        })
      }

      if (user.jrlSignedUp || user.familyCupSignedUp) {
        coachMenuItems.push({
          icon: <AssignmentIndOutlinedIcon />,
          label: 'Requirements',
          path: `${path}/requirements`,
        })
      }

      if (user.isAcademyOwner && !user.isAssumed) {
        coachMenuItems.push({
          icon: <PeopleOutlineOutlinedIcon />,
          label: 'Coaching Center Coaches',
          path: `${path}/academy-coaches`,
        })
        if (isCoachingCenterStudentListVisible) {
          coachMenuItems.push({
            icon: <PermContactCalendarOutlinedIcon />,
            label: 'Coaching Center Students',
            path: `${path}/coaching-center`,
          })
        }
      }
      if (user.isAcademyOwner) {
        coachMenuItems.push({
          icon: <GolferIcon size={20} color="primary" />,
          label: 'Bay Rentals',
          path: `${path}/bay-reservations`,
        })
      }
      return coachMenuItems
    } else {
      return [
        {
          icon: <AttachMoneyOutlinedIcon />,
          label: 'Payments',
          path: `${path}/payments`,
        },
        {
          icon: <AccountCircleOutlinedIcon />,
          label: 'Accounts',
          path: `${path}/students`,
        },
      ]
    }
  })()
  return (
    <ThemeProvider
      theme={extendTheme({
        ...themes.pgaCoach,
      })}
    >
      <Helmet>
        <title>MyPGA Account</title>
        <meta name="description" content="MyPGA Account" />
        <link rel="icon" href="/pga-coach-favicon.ico" />
      </Helmet>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Switch>
          <Route
            path={`${path}/students/:studentId/profile/edit`}
            component={GolferIntakeForm}
          />
          <Shell
            menuItems={menuItems}
            toolbarContent={
              <>
                <DashboardReturnLink />
                <Box flexGrow={1} />
                <AccountDropdown />
              </>
            }
          >
            <Route
              exact
              path={`${path}/academy-coaches`}
              component={AccountAcademyCoachesPage}
            />
            <Route
              exact
              path={`${path}/coaching-center`}
              component={CoachingCenterPageHeader}
            />
            {user.isAcademyOwner && (
              <Route
                exact
                path={`${path}/bay-reservations`}
                component={ReservationsShowPage}
              />
            )}
            <Route
              exact
              path={`${path}/coaching-center/:slug`}
              component={CoachingCenterStudentsList}
            />
            <Route
              exact
              path={`${path}/requirements`}
              component={withProtectedRoute(RequirementsPage, roles.COACH)}
            />
            <Route
              exact
              path={`${path}/my-plan`}
              component={withProtectedRoute(MyPlan, roles.COACH)}
            />
            <Route exact path={path} component={AccountDetails} />
            <Route exact path={`${path}/students`} component={StudentsPage} />
            <Route
              exact
              path={`${path}/students/:studentId/edit`}
              component={EditGolferAccountDetails}
            />
            <Route
              exact
              path={`${path}/students/:studentId`}
              component={GolferAccountDetails}
            />
            <SensitiveInfoWrapper enableFallback>
              <Route exact path={`${path}/payments`} component={PaymentsPage} />
              <Route
                exact
                path={`${path}/payments/payment-accounts`}
                component={PaymentAccountsPage}
              />
              <Route
                exact
                path={`${path}/payments/payment-accounts/new`}
                component={NewPaymentAccountCallbackPage}
              />
              <Route
                exact
                path={`${path}/payments/transactions`}
                component={TransactionsPage}
              />
              <Route
                exact
                path={`${path}/payments/transactions/:transactionId`}
                component={TransactionDetailPage}
              />
            </SensitiveInfoWrapper>
          </Shell>
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withProtectedRoute(AccountRoutes, [roles.COACH, roles.CONSUMER])
