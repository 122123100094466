import { gql } from '@apollo/client'

export const GET_GROUP_EVENT = gql`
  query ThingsToDoRegistrationGroupEvent($slug: String!) {
    groupEvents(slug: $slug) {
      id
      title
      slug
      startDateTime
      endDateTime
      paymentRecurrenceSchedule
      numberOfPayments
      description
      lessonCount
      academy {
        slug
      }
      coachFacility {
        name
        street
        city
        zipCode
      }
      coach {
        id
        name
        externalId
        pgaCoachEligible
        coachProfile {
          profilePhoto
          title
          memberType
          certificationLevel
        }
      }
      featuredImageUrl
      priceInCents
      totalPriceInCents
      isCanceled
      externalRegistrationUrl
      registrationClosed
      registrationFull
      registrationCount
      maxAttendance
      requiresAccessCode
      acceptsOnlinePayments
      timezone
      tags
      sessions {
        startDateTime
        endDateTime
      }
    }
  }
`

export const REGISTER_GROUP_EVENT = gql`
  mutation RegisterGroupEvent(
    $id: ID!
    $participants: [StudentAttributes!]!
    $contact: ContactAttributes
    $stripeToken: String
  ) {
    registerGroupEvent(
      id: $id
      participants: $participants
      contact: $contact
      stripeToken: $stripeToken
    ) {
      success
      message
    }
  }
`
