import React from 'react'
import AuthContext from './AuthContext'
import { getToken } from './token'
import auth0 from './client'
import Error from './Error'
import { API_ROOT, APP_VERSION } from 'env'
import {
  getAssumedUser,
  clearAssumedUser,
  getAssumingUserRestrictions,
} from './impersonation'
import intersection from 'lodash/intersection'
import castArray from 'lodash/castArray'
import LinearProgress from '@material-ui/core/LinearProgress'

const currentUserQuery = `
{
  currentUser {
    externalId,
    programAdmin,
    familyCupSignedUp,
    jrlSignedUp,
    roles
    attributes {
      __typename
      ... on Coach {
        id
        name
        email
        intercomEmail
        bookingUrl
        profileUrl
        pgaCoachEligible
        pgaHopeCoach
        hasPaidTierSubscription
        coachProfile {
          slug
          profilePhoto
          unviewedLeadCount
          title
        }
        employments {
          role
        }
      }
      ... on Contact {
        id
        firstName
        lastName
        email
        phoneNumber
        marketingOptInAt
      }
    }
  }
}
`

const CODE_RE = /[?&]code=[^&]+/
const STATE_RE = /[?&]state=[^&]+/
const ERROR_RE = /[?&]error=[^&]+/

// avoid running the Auth0 redirect callback on the pages Stripe calls back to when the user is creating a Connect Account
const SKIPPED_REDIRECT_CALLBACK_RE = /(stripe-signup|payment-accounts\/new)/

const hasAuthRedirectParams = (searchParams = window.location.search) =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams)

function withAuthProvider(App) {
  class WithAuthProvider extends React.Component {
    static displayName = `withAuthProvider(${App.displayName ||
      App.name ||
      'App'})`

    constructor(props) {
      super(props)

      this.state = {
        user: null,
        isRedirecting: false,
        error: null,
      }

      this.login = this.login.bind(this)
      this.logout = this.logout.bind(this)
      this.setUserProperties = this.setUserProperties.bind(this)
    }

    componentDidMount() {
      const handleRedirectCallback = async () => {
        try {
          // prevent the app from rendering while the redirect callback is running
          this.setState({ isRedirecting: true })
          clearAssumedUser(false)
          await auth0.logout({ openUrl: false })
          // handle the redirect callback
          const { appState } = await auth0.handleRedirectCallback()
          if (appState?.returnTo) {
            window.location = appState.returnTo
          }
        } catch (error) {
          this.setState({ error })
          console.error(error)
          window.rg4js &&
            window.rg4js('send', {
              error: error,
              tags: ['auth0'],
            })
        }
      }
      const skipRedirectCallback = SKIPPED_REDIRECT_CALLBACK_RE.test(
        window.location.pathname,
      )
      if (hasAuthRedirectParams() && !skipRedirectCallback) {
        handleRedirectCallback()
      }
    }

    async login(callback) {
      const authToken = await getToken()

      if (authToken != null) {
        let currentUser
        let userProperties = {
          roles: [],
          hasRole(roles) {
            return intersection(this.roles, castArray(roles)).length > 0
          },
        }

        try {
          const assumedUser = getAssumedUser()
          const hideSensitiveInformation = getAssumingUserRestrictions()
          const res = await fetch(`${API_ROOT}/graphql`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
              ...(assumedUser && { 'X-PGA-Impersonation': assumedUser }),
              'X-Client-Version': APP_VERSION,
              'X-Client-Name': 'my-pga-com',
            },
            body: JSON.stringify({
              query: currentUserQuery,
            }),
          })
          const json = await res.json()
          currentUser = json?.data?.currentUser

          if (currentUser) {
            userProperties = {
              ...userProperties,
              externalId: currentUser.externalId,
              programAdmin: currentUser.programAdmin,
              familyCupSignedUp: currentUser.familyCupSignedUp,
              jrlSignedUp: currentUser.jrlSignedUp,
              onboarding: false,
              roles: currentUser.roles,
              type: currentUser.attributes.__typename,
              id: currentUser.attributes.id,
              ...(currentUser.attributes.__typename === 'Coach' && {
                name: currentUser.attributes.name,
                firstName: currentUser.attributes.name
                  .split(' ')
                  .slice(0, -1)
                  .join(' '),
                lastName: currentUser.attributes.name
                  .split(' ')
                  .slice(-1)
                  .join(' '),
                email: currentUser.attributes.email,
                intercomEmail: currentUser.attributes.intercomEmail,
                pgaCoachEligible: currentUser.attributes.pgaCoachEligible,
                coach: currentUser.attributes,
                isAcademyOwner: currentUser.attributes.employments.some(
                  e => e.role === 'OWNER',
                ),
                isAcademyStaff: currentUser.attributes.employments.some(
                  e => e.role === 'STAFF',
                ),
              }),
              ...(currentUser.attributes.__typename === 'Contact' && {
                name: `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`,
                firstName: currentUser.attributes.firstName,
                lastName: currentUser.attributes.lastName,
                email: currentUser.attributes.email,
                intercomEmail: currentUser.attributes.email,
                phoneNumber: currentUser.attributes.phoneNumber,
              }),
              isAssumed: assumedUser != null,
              hideSensitiveInformation: hideSensitiveInformation != null,
              marketingOptInAt: currentUser.attributes.marketingOptInAt,
            }
          }
        } catch (e) {
          console.error(e)
          window.rg4js &&
            window.rg4js('send', {
              error: e,
              tags: ['auth'],
            })
        }

        this.setState(
          {
            user: userProperties,
          },
          () => callback && callback(),
        )
      } else {
        callback && callback()
      }
    }

    logout() {
      clearAssumedUser(false)
      this.setState({ user: null })
      auth0.logout()
    }

    setUserProperties(userProperties) {
      this.setState({ user: { ...this.state.user, ...userProperties } })
    }

    render() {
      const { user, isRedirecting, error } = this.state
      if (error) return <Error error={error} />
      if (isRedirecting) return <LinearProgress color="secondary" />
      return (
        <AuthContext.Provider
          value={{
            isLoggedIn: user != null,
            user,
            login: this.login,
            logout: this.logout,
            setUserProperties: this.setUserProperties,
          }}
        >
          <App {...this.props} />
        </AuthContext.Provider>
      )
    }
  }
  return WithAuthProvider
}

export default withAuthProvider
