import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { useIsMobile } from 'lib/hooks'
import PropTypes from 'prop-types'
import BookingDateOrDuration from './BookingDateOrDuration'
import pluralize from 'lib/pluralize'
import { getInitials } from 'lib/text-display'
import BookingPrice from './BookingPrice'
import AlertWithDivider from './AlertWithDivider'
import RedeemIcon from '@material-ui/icons/Redeem'
import Chip from '@material-ui/core/Chip'
import CoachLogo from 'components/coach-logo'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
  },
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '1rem',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1rem',
      paddingBottom: '0.25rem',
    },
  },
  attributes: {
    color: theme.palette.grey.dark,
    fontWeight: 600,
    fontSize: '1rem',
  },
  icons: {
    color: theme.palette.secondary.dark,
  },
  avatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '86px',
    height: '86px',
    marginTop: '0.25rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
  },
  mobileAvatar: {
    border: '2px solid rgb(255, 255, 255)',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 1px 1px',
    width: '3.3rem',
    height: '3.3rem',
    color: '#fff',
    backgroundColor: '#d8d8d8',
  },
  coachCardName: {
    marginTop: '1rem',
    color: theme.palette.primary.main,
  },
  coachCardSubtitle: {
    color: theme.palette.secondary.dark,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  list: {
    marginBottom: '.75rem',
    '& li': {
      lineHeight: '.75rem',
    },
  },
  seeMoreToggle: {
    textDecoration: 'underline',
    marginBottom: '1rem',
  },
  avatarInitials: {
    fontSize: '2.5rem',
  },
  description: {
    '& p': {
      margin: '0',
      fontWeight: '600',
    },
  },
  chipSpacing: {
    marginLeft: '1rem',
  },
  filledChip: {
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '4px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '700',
    letterSpacing: 1.5,
    height: '22px',
    fontSize: '12px',
    marginBottom: '.25rem',
    position: 'relative',
    textTransform: 'uppercase',
    '& span': {
      paddingLeft: '.25rem',
      paddingRight: '.25rem',
    },
    '& svg': {
      fill: theme.palette.secondary.contrastText,
      width: 14,
      height: 14,
      marginRight: '1px',
      marginBottom: '2px',
    },
  },
}))

const giftingTerms =
  "Upon purchase completion, you'll have the option to gift the lesson pack. A lesson pack can only be gifted once. Once a credit has been redeemed, the pack can no longer be gifted.To gift later, log in to my.pga.com with your credentials."

const MobileHeader = ({ lessonType, coach, classes, selectedLessonPack }) => {
  const theme = useTheme()
  const [showSeeMore, setShowSeeMore] = useState(false)
  const {
    title,
    durationInMinutes,
    priceInCents,
    facilities,
    description,
    lessonPacks,
    startDateTime,
    giftingEnabled,
  } = lessonType
  const isLessonPackOffering = lessonPacks?.length > 0
  return (
    <>
      <Grid item xs>
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
          <Grid item xs={2} style={{ alignSelf: 'center' }}>
            {coach.coachProfile?.profilePhoto != null ? (
              <Avatar
                className={classes.mobileAvatar}
                alt={coach.name}
                src={coach.coachProfile.profilePhoto}
              >
                {coach.name}
              </Avatar>
            ) : (
              <Avatar className={classes.mobileAvatar}>{coach.initials}</Avatar>
            )}
          </Grid>
          <Grid item xs={10} style={{ alignSelf: 'center' }}>
            <Box display="flex" alignItems="center">
              <Box pl={1.5}>
                <Typography variant="h4" gutterBottom>
                  {coach.name}
                </Typography>
                {coach.coachProfile?.title != null ? (
                  <Typography
                    variant="subtitle1"
                    className={classes.coachCardSubtitle}
                  >
                    {coach.coachProfile.title}
                  </Typography>
                ) : null}
              </Box>
              <Box marginLeft="auto">
                <CoachLogo
                  memberType={coach.coachProfile?.memberType}
                  certificationLevel={coach.coachProfile?.certificationLevel}
                  size="50px"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={1}>
          <Typography variant={'h3'} className={classes.title}>
            {title}
            {giftingEnabled && (
              <Chip
                icon={<RedeemIcon />}
                className={classes.filledChip}
                label="Giftable"
              />
            )}
          </Typography>
          <Grid item>
            {!showSeeMore && (
              <List dense={true}>
                <Typography
                  variant="subtitle1"
                  onClick={() => setShowSeeMore(true)}
                  className={classes.seeMoreToggle}
                >
                  Read more
                </Typography>
              </List>
            )}
            {showSeeMore && (
              <>
                {description && (
                  <Typography variant="body1" className={classes.description}>
                    <ReactMarkdown
                      components={{
                        p: ({ children }) => (
                          <p style={{ marginBottom: '1em' }}>{children}</p>
                        ),
                      }}
                    >
                      {description}
                    </ReactMarkdown>
                  </Typography>
                )}
                {giftingEnabled && (
                  <AlertWithDivider
                    icon={<RedeemIcon />}
                    text={giftingTerms}
                    variant="gold"
                  />
                )}
                <List dense={true}>
                  <ListItem disableGutters={true}>
                    <ListItemIcon>
                      <GolfCourseIcon className={classes.icons} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.attributes }}
                      primary={facilities[0].name}
                    />
                  </ListItem>
                  {facilities[0].city && facilities[0].state && (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <PlaceOutlinedIcon className={classes.icons} />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={`${facilities[0].city}, ${facilities[0].state}`}
                      />
                    </ListItem>
                  )}
                  {selectedLessonPack ? (
                    <ListItem disableGutters={true}>
                      <ListItemIcon>
                        <ConfirmationNumberOutlinedIcon
                          fontSize="small"
                          className={classes.icons}
                          color={theme.palette.secondary.dark}
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.attributes }}
                        primary={`${selectedLessonPack.quantity} ${pluralize(
                          selectedLessonPack.quantity,
                          'lesson',
                        )}`}
                      />
                    </ListItem>
                  ) : (
                    <>
                      <BookingDateOrDuration
                        durationInMinutes={durationInMinutes}
                        timezone={facilities[0].timezone}
                        classes={classes}
                        startTimestamp={startDateTime}
                      />
                      <BookingPrice
                        priceInCents={priceInCents}
                        isLessonPackOffering={isLessonPackOffering}
                        classes={classes}
                      />
                    </>
                  )}
                </List>
                <Typography
                  variant="subtitle1"
                  onClick={() => setShowSeeMore(false)}
                  className={classes.seeMoreToggle}
                >
                  Read less
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
const FullHeader = ({ lessonType, coach, classes, selectedLessonPack }) => {
  const {
    title,
    durationInMinutes,
    priceInCents,
    facilities,
    description,
    lessonPacks,
    startDateTime,
    giftingEnabled,
  } = lessonType
  const isLessonPackOffering = lessonPacks?.length > 0
  const theme = useTheme()
  return (
    <>
      <Grid item sm={8}>
        <Grid container direction="column" spacing={1}>
          <Typography variant="h3" className={classes.title}>
            {title}
            {giftingEnabled && (
              <Chip
                icon={<RedeemIcon />}
                className={`${classes.filledChip} ${classes.chipSpacing}`}
                label="Giftable"
              />
            )}
          </Typography>
          {description && (
            <Typography variant="body1" className={classes.description}>
              <ReactMarkdown
                components={{
                  p: ({ children }) => (
                    <p style={{ marginBottom: '1em' }}>{children}</p>
                  ),
                }}
              >
                {description}
              </ReactMarkdown>
            </Typography>
          )}
          {giftingEnabled && (
            <AlertWithDivider
              icon={<RedeemIcon />}
              text={giftingTerms}
              variant="gold"
            />
          )}
          <List dense={true} className={classes.list}>
            <ListItem disableGutters={true}>
              <ListItemIcon>
                <GolfCourseIcon className={classes.icons} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.attributes }}
                primary={facilities[0].name}
              />
            </ListItem>
            {facilities[0].city && facilities[0].state && (
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <PlaceOutlinedIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={`${facilities[0].city}, ${facilities[0].state}`}
                />
              </ListItem>
            )}
            {selectedLessonPack ? (
              <ListItem disableGutters={true}>
                <ListItemIcon>
                  <ConfirmationNumberOutlinedIcon
                    fontSize="small"
                    className={classes.icons}
                    color={theme.palette.secondary.dark}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.attributes }}
                  primary={`${selectedLessonPack.quantity} ${pluralize(
                    selectedLessonPack.quantity,
                    'lesson',
                  )}`}
                />
              </ListItem>
            ) : (
              <>
                <BookingDateOrDuration
                  durationInMinutes={durationInMinutes}
                  timezone={facilities[0].timezone}
                  classes={classes}
                  startTimestamp={startDateTime}
                />
                <BookingPrice
                  priceInCents={priceInCents}
                  isLessonPackOffering={isLessonPackOffering}
                  classes={classes}
                />
              </>
            )}
          </List>
        </Grid>
      </Grid>
      <Grid item sm={4}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box textAlign="center">
            {coach.coachProfile?.profilePhoto != null ? (
              <Avatar
                className={classes.avatar}
                alt={coach.name}
                src={coach.coachProfile.profilePhoto}
              >
                {coach.name}
              </Avatar>
            ) : (
              <Avatar
                className={`
              ${classes.avatar} ${classes.avatarInitials}`}
              >
                {coach.initials}
              </Avatar>
            )}
          </Box>
          <Box textAlign="center">
            <Typography variant="h4" className={classes.coachCardName}>
              {coach.name}
            </Typography>
            {coach.coachProfile?.title != null ? (
              <Box textAlign="center">
                <Typography
                  variant="subtitle1"
                  className={classes.coachCardSubtitle}
                >
                  {coach.coachProfile.title}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box mt={1}>
            <CoachLogo
              memberType={coach.coachProfile?.memberType}
              certificationLevel={coach.coachProfile?.certificationLevel}
              size="50px"
            />
          </Box>
        </Box>
      </Grid>
    </>
  )
}
const CoachLessonHeader = ({ lessonType, coach, selectedLessonPack }) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const initials = getInitials(coach.name)
  coach = { ...coach, initials }
  return (
    <Container className={classes.container}>
      <Grid
        container
        className={classes.root}
        spacing={2}
        alignItems="center"
        direction="row"
      >
        {isMobile ? (
          <MobileHeader
            lessonType={lessonType}
            coach={coach}
            selectedLessonPack={selectedLessonPack}
            classes={classes}
          />
        ) : (
          <FullHeader
            lessonType={lessonType}
            coach={coach}
            classes={classes}
            selectedLessonPack={selectedLessonPack}
          />
        )}
      </Grid>
    </Container>
  )
}

CoachLessonHeader.propTypes = {
  lessonType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    durationInMinutes: PropTypes.number.isRequired,
    priceInCents: PropTypes.number,
    facilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        city: PropTypes.string,
        state: PropTypes.string,
        timezone: PropTypes.string,
      }),
    ).isRequired,
    description: PropTypes.string,
  }).isRequired,
  coach: PropTypes.shape({
    name: PropTypes.string.isRequired,
    coachProfile: PropTypes.shape({
      title: PropTypes.string,
      profilePhoto: PropTypes.string,
    }),
  }).isRequired,
}
export default CoachLessonHeader
